import React, { useState, useEffect } from "react"
import Header from "../../molecules/header/header"
import Footer from "../../molecules/footer/footer"
import ButtonShop from "../../atoms/shop-button/shop-button"
import * as Sentry from '@sentry/react';
import { Integrations } from "@sentry/tracing";
import CookieConsent from "../../molecules/cookie-consent/cookie-consent"
import "./layout.scss"
import useFooterInfo from "../../../hooks/useFooterInfo";

const Layout = props => {
  const { children, activeMenu, logoMobile = true, logoSearch = true, shopButton = true, isProductDetail = false, showHeader = true } = props;
  const [modalActive, setmodalActive] = useState(null);
  const { footerInfo } = useFooterInfo()
  const logoInfo = footerInfo.allContentfulPetysHome.nodes[0].logo
  Sentry.init({ dsn: "https://1c2a2f8afbaf459c9b4db44f22d68ea0@o442738.ingest.sentry.io/5415237", integrations: [new Integrations.BrowserTracing(),], tracesSampleRate: 1.0, });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {showHeader &&
        (<Header
          footerInfo={footerInfo}
          activeMenu={activeMenu}
          modalActive={props.modalActive}
          logoSearch={logoSearch}
          logoMobile={logoMobile}
        />
        )}
      {modalActive}
      <div className="f-main-container">
        {children}
      </div>
      {process.env.ENVIRONMENT !== 'PDN' && <span className="f-env-watermark">{process.env.ENVIRONMENT}</span>}
      {shopButton && (isProductDetail === false) && < ButtonShop setmodalActive={setmodalActive} />}
      <CookieConsent logoUrl={logoInfo.file.url} />
      <Footer footerInfo={footerInfo} showHeader={showHeader} />
    </>
  )
}

export default Layout;
import { axiosInstance } from './axios-instance';
import * as endpoints from "../constants/apiConstants";
import userSessionStorage from "../utils/userSessionStorage"
class AccountService {

    static authentication(payload) {
        return axiosInstance.post(endpoints.LOGIN_USER, payload).then(res => res.data)
    }

    static sessionInfo(config) {
        return axiosInstance.get(endpoints.ACCOUNT_INFO, config).then(res => res.data)
    }

    static updateUser(payload, otp) {
        const session = new userSessionStorage()

        return axiosInstance.put(endpoints.ACCOUNT_INFO + `?code=${otp}`, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': session.getToken().idToken
            }
        }).then(res => res.data)
    }

    static updateDataPolicy(payload) {
        const session = new userSessionStorage()

        return axiosInstance.put(endpoints.UPDATE_DATA_POLICY , payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': session.getToken().idToken
            }
        }).then(res => res.data)
    }

    static deletePet(petId) {
        const session = new userSessionStorage()

        return axiosInstance.delete(endpoints.DELETE_PET + `/${petId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': session.getToken().idToken,
            }
        }).then(res => res.data)
    }

    static sendOTP(value, config) {
        var payload = {
            value
        }
        return axiosInstance.post(endpoints.SEND_OTP, payload, config).then(resp => resp.data)
    }

    static validateOTP(value, config) {
        var payload = {
            value
        }
        return axiosInstance.post(endpoints.VALIDATE_OTP, payload, config).then(resp => resp.data)
    }

    static recoveryPassword(value) {
        return axiosInstance.post(endpoints.RECOVERY_PASSWORD, { username: value }).then(resp => resp.data)
    }

    static changeRecoveryPassword(payload) {
        const session = new userSessionStorage()
        return axiosInstance.post(endpoints.RECOVERY_CHANGE_PASSWORD, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': session.getToken().idToken
            }
        }).then(resp => resp.data)
    }

    static changePassword(payload) {
        const session = new userSessionStorage()

        return axiosInstance.post(endpoints.CHANGE_PASSOWD, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': session.getToken().idToken
            }
        }).then(resp => resp.data)
    }
    static validateUser(payload) {
        return axiosInstance.post(endpoints.VALIDATE_USER_LOGIN, payload, { params: payload }).then(res => res.data)
    }

    static getChangeEmail() {
        const session = new userSessionStorage()
        return axiosInstance.get(endpoints.GET_CHANGE_EMAIL, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': session.getToken().idToken
            }
        }).then((res) => res.data)
    }

    static updateEmailChange = (newEmail) => {
        const session = new userSessionStorage()
        return axiosInstance.get(endpoints.UPDATE_EMAIL_CHANGE, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': session.getToken().idToken
            }, params: {
                newEmail
            }
        }).then((res) => res.data)
    }

    static deleteEmailChange = () => {
        const session = new userSessionStorage()
        return axiosInstance.delete(endpoints.DELATE_EMAIL_CHANGE, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': session.getToken().idToken
            }
        }).then((res) => res.data)
    }


    static confirmEmailChange = (code) => {
        const session = new userSessionStorage()
        return axiosInstance.get(endpoints.CONFIRM_EMAIL_CHANGE, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': session.getToken().idToken
            }, params: {
                code
            }
        }).then((res) => res.data)
    }

    static sendOTPNewEmail = (newEmail) => {
        const session = new userSessionStorage()
        return axiosInstance.get(endpoints.SEND_OTP_NEW_EMAIL, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': session.getToken().idToken
            }, params: {
                newEmail
            }
        }).then((res) => res.data)
    }


}

export default AccountService
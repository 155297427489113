import services from "./../services/account-service"
import { formatDateService } from "./../constants/dateConstants"

export default class userSessionStorage {
  getToken() {
    return localStorage.getItem("TOKEN_INFO") !== null
      ? { ...JSON.parse(localStorage.getItem("TOKEN_INFO")) }
      : null
  }

  getUserInfo() {
    return localStorage.getItem("USER_DATA") !== null
      ? { ...JSON.parse(localStorage.getItem("USER_DATA")) }
      : null
  }

  authLogin({
    payload,
    handleSuccess,
    handleError = null,
    handleFinally = null,
    saveStorage = true,
  }) {
    // payload={email,password,captcha}
    services
      .authentication(payload)
      .then(res => {
        if (res.status === 400 || res.status === 404) {
          if (handleError !== null) handleError(res)
        } else if (res.status === 200) {
          if (saveStorage) {
            localStorage.setItem(
              "TOKEN_INFO",
              JSON.stringify({
                accessToken: res.data.tokens.accessToken,
                expiresIn: res.data.tokens.expiresIn,
                idToken: res.data.tokens.idToken,
                // tokenType: res.data.tokens.tokenType,
                refreshToken: res.data.tokens.refreshToken,
                email: res.data.email,
              })
            )

            this.authUserInfo({ saveStorage: true })
          }
          if (handleSuccess !== null) handleSuccess(res)
        } else {
          console.log("Err: ", res)
        }
      })
      .catch(err => {
        console.log(err)
        if (handleError !== null)
          handleError({ userMessage: "Algo salió mal con la conexión." })
      })
      .finally(() => {
        if (handleFinally !== null) handleFinally()
      })
  }

  authUserInfo({
    handleSuccess,
    handleError = null,
    handleFinally = null,
    saveStorage = true,
  }) {
    let token = this.getToken()
    if (token) {
      var tokenId = token.idToken
      var config = {
        headers: {
          Authorization: tokenId,
        },
      }
      services
        .sessionInfo(config)
        .then(res => {
          const userData = res.data
          switch (res.status) {
            case 200:
              if (saveStorage) {
                if (userData?.birthday) {
                  let result = formatDateService(userData.birthday)
                  userData.day = result.day
                  userData.month = result.month
                  userData.year = result.year
                  userData.tycEmail = userData.tycEmail ? true : false
                  userData.tycSms = userData.tycSms ? true : false
                }
                localStorage.setItem("USER_DATA", JSON.stringify(userData))
              }
              if (handleSuccess !== null) handleSuccess({...res, data: userData})
              break
            case 400:
              if (handleError !== null) handleError({...res, data: userData})
              break
            default:
              console.log({...res, data: userData})
              break
          }
        })
        .catch(e => {
          if (handleError !== null) handleError(e)
          if (e?.response?.status === 401) {
            localStorage.removeItem("USER_DATA")
            localStorage.removeItem("TOKEN_INFO")
          }
          console.log(e)
        })
        .finally(() => {
          if (handleFinally !== null) handleFinally()
        })
    } else {
      if (handleError !== null) handleError()
      if (handleFinally !== null) handleFinally()
    }
  }

  destroySession() {
    localStorage.removeItem("USER_DATA")
    localStorage.removeItem("TOKEN_INFO")
  }

  getNoPrefix(num) {
    return num.slice(process.env.COUNTRY_PHONE_PREFIX.length, num.length)
  }
}

import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import siteUrl from '../../../utils/siteUrl';
import { unicodeUtil } from "../../../utils/unicode-util";

function SEO(props) {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const defaultImg = process.env.DEFAULT_LOGO;
  const meta = props.meta;
  let lang = props.lang;
  let title = props.title || site.siteMetadata.title;
  let metaDescription = props.description || site.siteMetadata.description;
  let author = site.siteMetadata.author;
  let siteUrlDynamics = props.slug || site.siteMetadata.siteUrl;
  let ogImageUrl = props.image || defaultImg;
  let script = props.script;

  // Organization Schema
  const org = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Petys",
    "alternateName": author,
    "url": siteUrl,
    "logo": defaultImg,
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": process.env.COUNTRY_CONTACT,
      "contactType": "customer service",
      "contactOption": "TollFree",
      "areaServed": "CO",
      "availableLanguage": "es"
    },
    "sameAs": "https://www.facebook.com/AmorPetys/"
  };

  // WebSite Schema
  const website = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "@id": siteUrl + "#website",
    "headline": "Petys",
    "name": "Petys",
    "description": unicodeUtil(site.siteMetadata.description),
    "url": siteUrl,
    "potentialAction": {
      "@type": "SearchAction",
      "target": siteUrl + "?s={search_term_string}",
      "query-input": "required name=search_term_string"
    }
  };

  let schemas = [];
  if (script) {
    schemas = [org, website, script];
  } else {
    schemas = [org, website];
  }

  const schemaOrgJSONLD = {
    type: 'application/ld+json',
    innerHTML: JSON.stringify(schemas)
  };

  // console.log("title", title);
  // console.log("metaDescription", metaDescription);

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s`}
      link={[
        {
          "rel": "alternate",
          "href": siteUrl + props?.slug,
          "hrefLang": "es-CO"
        }
      ]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `image`,
          content: ogImageUrl,
        },
        // {
        //   property: `fb:app_id`,
        //   content: `554978031914420`,
        // },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogImageUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: siteUrlDynamics,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: ogImageUrl,
        },
        {
          name: 'google-site-verification',
          content: process.env.GOOGLE_SITE_VERIFICATION
        },
      ].concat(meta)}
      script={[schemaOrgJSONLD]}
    />
  )
}

SEO.defaultProps = {
  lang: `es-CO`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO;
import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import homeLink from "../../../utils/siteUrl"
import useMobile from "../../../hooks/useMobile"
import MenuMobile from "../../molecules/menu-mobile/menu-mobile"
import userSessionStorage from "../../../utils/userSessionStorage"
import "./header.scss"

function Header(props) {
  const headerInfo = useStaticQuery(
    graphql`
      query HeaderQuery {
        allContentfulPetysHome(
          filter: { menu: { titulo: { eq: "Menú Transversal" } } }
        ) {
          nodes {
            menu {
              titulo
              opcionesMenu {
                titulo
                nombreDeLaOpcion
                redireccionDeLaOpcion
              }
            }
          }
        }
      }
    `
  )

  const session = new userSessionStorage()

  const { footerInfo, activeMenu } = props
  const isMobile = useMobile()
  const [menuMobile, setMenuMobile] = useState(false)
  const [menuMobileOpen, setMenuMobileOpen] = useState(false)
  const [userData, setUserData] = useState()

  const menuOptions =
    headerInfo.allContentfulPetysHome.nodes[0].menu.opcionesMenu
  const registerOption = headerInfo.allContentfulPetysHome.nodes[0].menu.opcionesMenu.filter(
    item => item.nombreDeLaOpcion.toLowerCase().includes("registro")
  )[0]
  const loginOption = headerInfo.allContentfulPetysHome.nodes[0].menu.opcionesMenu.filter(
    item => item.nombreDeLaOpcion.toLowerCase().includes("sesión")
  )[0]
  const responsabilityOption = headerInfo.allContentfulPetysHome.nodes[0].menu.opcionesMenu.filter(
    item => item.nombreDeLaOpcion.toLowerCase().includes("responsabilidad")
  )[0]
  const logoInfo = footerInfo.allContentfulPetysHome.nodes[0].logo
  const termsAndConditions =
    footerInfo.allContentfulPetysHome.nodes[0].footerTituloTerminosYPoliticas
  const termsAndConditionsRedirec =
    footerInfo.allContentfulPetysHome.nodes[0]
      .footerRedireccionTerminosYPoliticas
  const policyPrivacy =
    footerInfo.allContentfulPetysHome.nodes[0].footerTituloPolticaDeDatos
  const policyPrivacyRedirec =
    footerInfo.allContentfulPetysHome.nodes[0].footerRedireccionPoliticaDeDatos
  const facebookInfo = {
    redirection:
      footerInfo.allContentfulPetysHome.nodes[0].menu.redesSocialesFacebook,
    imagen:
      footerInfo.allContentfulPetysHome.nodes[0].menu
        .redesSocialesFacebookImagen,
  }
  const instagramInfo = {
    redirection:
      footerInfo.allContentfulPetysHome.nodes[0].menu.redesSocialesInstagram,
    imagen:
      footerInfo.allContentfulPetysHome.nodes[0].menu
        .redesSocialesInstagramImagen,
  }
  const youtubeInfo = {
    redirection:
      footerInfo.allContentfulPetysHome.nodes[0].menu.redesSocialesYoutube,
    imagen:
      footerInfo.allContentfulPetysHome.nodes[0].menu
        .redesSocialesYoutubeImagen,
  }

  //Este metodo es para cuando el menú esta abierto, se setea medio segundo después el estado para poder ejecutar la animación
  const onHandleMenu = () => {
    if (menuMobileOpen === true) {
      setMenuMobile(false)
      setTimeout(() => {
        setMenuMobileOpen(false)
      }, 600)
    } else {
      setMenuMobileOpen(true)
      setTimeout(() => {
        setMenuMobile(true)
      }, 100)
    }
  }

  const closeSession = () => {
    session.destroySession()
    window.location.reload()
    localStorage.removeItem("isFromRegister")
  }

  React.useEffect(() => {
    let dataUser = session.getUserInfo()
    let token = session.getToken()

    if (dataUser && dataUser !== undefined) {
      dataUser && setUserData(dataUser)
    } else if (token && token !== undefined) {
      session.authUserInfo({
        saveStorage: true,
        handleSuccess: res => {
          setUserData(res.data)
        },
      })
    }
  }, [])

  return (
    <>
      {props.modalActive}
      {props.logoSearch && (
        <a href={homeLink + "buscador/"} className="f-link-item">
          <i className="icon-BUSCADOR f-search-icon-mobile"></i>
        </a>
      )}
      {props.logoMobile && (
        <a href={homeLink} className="f-logo-container-mobile">
          <img src={logoInfo.file.url} alt={logoInfo.description} />
        </a>
      )}

      <header className="f-header-container">
        <a href={homeLink} className="f-logo-container">
          <img src={logoInfo.file.url} alt={logoInfo.description} />
        </a>

        <div className="f-nav-container">
          <div className={"f-nav-sections" + (userData ? " f-nav-sections--loged": "")}>
            {menuOptions.map((itemMenu, index) => {
              let nameItem = itemMenu.nombreDeLaOpcion
                .split(" ")[0]
                .toLowerCase()
              let activeMenuCopy = activeMenu
              let clasActiveValidation =
                activeMenuCopy === index ? " active" : ""

              return (
                itemMenu.nombreDeLaOpcion.toLowerCase() === 'registro' ?
                <a
                  href={itemMenu.redireccionDeLaOpcion}
                  className={
                    "f-nav-item " +
                    "f-nav-item-".concat(nameItem) +
                    clasActiveValidation
                  }
                  key={index}
                >
                  <i className={"icon-" + nameItem.toUpperCase()}></i>
                  {itemMenu.nombreDeLaOpcion}
                </a>
                : <a
                  href={
                    homeLink +
                    (itemMenu.redireccionDeLaOpcion
                      ? itemMenu.redireccionDeLaOpcion
                      : "")
                  }
                  className={
                    "f-nav-item " +
                    "f-nav-item-".concat(nameItem) +
                    clasActiveValidation
                  }
                  key={index}
                >
                  <i className={"icon-" + nameItem.toUpperCase()}></i>
                  {itemMenu.nombreDeLaOpcion}
                </a>
              )
            })}
            <div className="f-nav-item f-nav-item-menu" onClick={onHandleMenu}>
              <i className="icon-MENU"></i>Menú
            </div>
          </div>

          {!isMobile && (
            <div className="f-links-container">
              <a
                href={homeLink + "buscador/"}
                className="f-link-item f-search-icon"
              >
                <i className="icon-BUSCADOR"></i>
              </a>

              {!userData && (
                <>
                  {loginOption && (
                    <a
                      href={homeLink + loginOption.redireccionDeLaOpcion}
                      className="f-link-item f-link-item__login"
                    >
                      {loginOption.nombreDeLaOpcion}
                    </a>
                  )}

                  <a
                    href={registerOption.redireccionDeLaOpcion}
                    className="f-link-item f-register-button"
                  >
                    <i className="icon-REGISTRO"></i>
                    {registerOption.nombreDeLaOpcion}
                  </a>
                </>
              )}
              <div className="f-redes-container">
                <a
                  href={instagramInfo.redirection}
                  target="_blank"
                  className="f-redes-item"
                >
                  <i className="icon-instagram"></i>
                </a>
                <a
                  href={facebookInfo.redirection}
                  target="_blank"
                  className="f-redes-item"
                >
                  <i className="icon-facebook"></i>
                </a>
                <a
                  href={youtubeInfo.redirection}
                  target="_blank"
                  className="f-redes-item"
                >
                  <i className="icon-youtube"></i>
                </a>
              </div>
            </div>
          )}

          {userData && userData.name && (
            <div className="f-nav-perfil">
              <a
                className="f-nav-perfil__user"
                href={homeLink + "mi-cuenta/perfil"}
              >
                <i className="icon-REGISTRO"></i> ¡Hola <b>{userData.name}</b>!
              </a>
              <p className="f-nav-perfil__closeSession" onClick={closeSession}>
                Cerrar sesión
              </p>
            </div>
          )}
        </div>

        {isMobile && menuMobileOpen && (
          <MenuMobile
            facebookInfo={facebookInfo}
            instagramInfo={instagramInfo}
            youtubeInfo={youtubeInfo}
            responsabilityOption={responsabilityOption}
            termsAndConditions={termsAndConditions}
            termsAndConditionsRedirec={termsAndConditionsRedirec}
            onCloseAction={onHandleMenu}
            opened={menuMobile}
            policyPrivacyRedirec={policyPrivacyRedirec}
            policyPrivacy={policyPrivacy}
            registerOption={registerOption}
            loginOption={loginOption}
            userData={userData}
            closeSession={closeSession}
          />
        )}
      </header>
    </>
  )
}

export default Header

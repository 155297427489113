import { graphql, useStaticQuery } from "gatsby"

export default function useFooterInfo() {
  const footerInfo = useStaticQuery(
    graphql`
        query FooterQuery {
          allContentfulPetysHome {
            nodes {
              logo {
                description
                title
                file {
                  url
                }
              }
              footerTituloResponsabilidadSocial
              footerRedireccionResponsabilidadSocial
              footerTituloNosotros
              footerRedireccionNosotros
              footerTituloContactanos
              footerRedireccionContactanos
              footerRedireccionTerminosYPoliticas
              footerDerechosYLineaDeAtencion
              menu {
                redesSocialesFacebook
                redesSocialesFacebookImagen {
                  title
                  description
                  file {
                    url
                  }
                }
                redesSocialesInstagram
                redesSocialesInstagramImagen {
                  title
                  description
                  file {
                    url
                  }
                }
                redesSocialesYoutube
                redesSocialesYoutubeImagen {
                  title
                  description
                  file {
                    url
                  }
                }
              }
              footerTituloTerminosYPoliticas
              footerTituloPolticaDeDatos
              footerRedireccionPoliticaDeDatos
            }
          }
        }
      `
  );

  return {
    footerInfo
  }
}

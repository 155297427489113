let url = process.env.ENVIRONMENT === 'STG' ? 'https://u79iu4uev0.execute-api.us-east-1.amazonaws.com/dev/petys' :
  process.env.ENVIRONMENT === 'PDN' ? 'https://2blh4fqkq6.execute-api.us-east-1.amazonaws.com/pdn/petys' :
    'https://u79iu4uev0.execute-api.us-east-1.amazonaws.com/dev/petys';

export const CLIENT_ID = process.env.ENVIRONMENT !== 'PDN' ? '5t514p7sfpv6peuu5jtm9udpj1' : '2n5940laerq12fdi68tttj94uj';
export const REFRESH_TOKEN = process.env.ENVIRONMENT !== 'PDN' ? 'https://petys-dev.auth.us-east-1.amazoncognito.com/oauth2/token' : 'https://petys-pdn.auth.us-east-1.amazoncognito.com/oauth2/token';

//Contact variables
export const WebToCaseUrl = process.env.ENVIRONMENT === 'STG' ? 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8' :
  process.env.ENVIRONMENT === 'PDN' ? 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8' :
    'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8';
// export const ORGID = process.env.ENVIRONMENT !== 'PDN' ? '00Df0000003f4DZ' : '';
// export const RECORDTYPE = process.env.ENVIRONMENT !== 'PDN' ? '00Df0000003f4DZ' : '';
export const ORGID = process.env.ENVIRONMENT !== 'PDN' ? '00D3j0000001yNQ' : '00D3j0000001yNQ';
export const RECORDTYPE = process.env.ENVIRONMENT !== 'PDN' ? '0123j000000X9zrAAC' : '0123j000000X9zrAAC';

//product
export const COMMENT_PRODUCT = url + "/product/api/v1/rate/comment";
export const RATE_PRODUCT = url + "/product/api/v1/rate";
export const RATE_USER_PRODUCT = url + "/product/api/v1/rate/user";

//Articles - Blog Experts
export const LIKE_ARTICLE = url + "/content/api/v1/content/like";
export const COMMENT_ARTICLE = url + "/content/api/v1/content/comment";
export const READ_ARTICLE = url + "/content/api/v1/content/read";

//Register
export const REGISTER_USER = url + "/register/api/v1/register";
export const VALIDATE_USER = url + "/register/api/v1/validate-username";
export const CONFIRMATION_USER = url + "/register/api/v1/user/confirmation";
export const RESEND_CODE = url + "/register/api/v1/user/resend-code";
export const INFO_USER_CODE = url + "/register/api/v1/user/info";
export const DELETE_PET_REGISTER = url + "/register/api/v1/user/pet";

//Account
export const LOGIN_USER = url + "/authentication/api/v1/auth";
export const ACCOUNT_INFO = url + "/account/api/v1/profile";
export const DELETE_PET = url + "/account/api/v1/profile/pet";
export const RECOVERY_PASSWORD = url + "/recovery/api/v1/recovery";
export const RECOVERY_CHANGE_PASSWORD = url + "/recovery/api/v1/change-recovery";
export const CHANGE_PASSOWD = url + "/recovery/api/v1/change";
export const VALIDATE_USER_LOGIN = url + "/authentication/api/v1/validate-username";
export const GET_CHANGE_EMAIL = url + "/account/api/v1/list-email-change"
export const UPDATE_EMAIL_CHANGE = url + "/account/api/v1/update-email-change"
export const DELATE_EMAIL_CHANGE = url + "/account/api/v1/delete-email-change"
export const CONFIRM_EMAIL_CHANGE = url + "/account/api/v1/confirm-email-change"
export const UPDATE_DATA_POLICY = url + "/account/api/v1/data-policy"

//OTP
export const SEND_OTP = url + "/otp/api/v1/otp";
export const VALIDATE_OTP = url + "/otp/api/v1/validate-otp";
export const SEND_OTP_NEW_EMAIL = url + "/otp/api/v1/send-otp-code-new-email"

// Activitys
export const INSTAGRAM_COMPETITION = url + '/competition/api/v1/participation';

//MockConst
export const MockConst = 'https://api.myjson.com/bins/18rx45';

// Get ip Address
export const GET_IP_ADDRESS = 'https://jsonip.com/';

//Login

export const loginUser = url + "/authentication/api/v1/auth";
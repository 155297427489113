import { DateTime } from "luxon"

export const months = [
  {
    month: "Enero",
    number: 1,
  },
  {
    month: "Febrero",
    number: 2,
  },
  {
    month: "Marzo",
    number: 3,
  },
  {
    month: "Abril",
    number: 4,
  },
  {
    month: "Mayo",
    number: 5,
  },
  {
    month: "Junio",
    number: 6,
  },
  {
    month: "Julio",
    number: 7,
  },
  {
    month: "Agosto",
    number: 8,
  },
  {
    month: "Septiembre",
    number: 9,
  },
  {
    month: "Octubre",
    number: 10,
  },
  {
    month: "Noviembre",
    number: 11,
  },
  {
    month: "Diciembre",
    number: 12,
  },
]

export var datesArray = []

export const DaysOfMonth = (month, year = 2020) => {
  if (month === "" || !month) return false
  var dt = DateTime.local().set({
    month: parseInt(month),
    year: parseInt(year === "" ? 2020 : year),
  })

  let monthDate = dt.daysInMonth
  let i

  datesArray = []

  for (i = 1; i < monthDate + 1; i++) {
    datesArray.push(i)
  }

  return monthDate
}

export let yearsArray = []

export const years = (startY, endY) => {
  let i
  if (yearsArray.length === 0) {
    for (i = endY; i > startY - 1; i--) {
      yearsArray.push(i)
    }
  }
}

export const isAdult = (day, month, year) => {
  var actualDate = DateTime.fromISO(DateTime.local().toISODate())
  var userDate = DateTime.fromISO(
    `${year}-${month.length === 1 ? "0" + month : month}-${
      day.length === 1 ? "0" + day : day
    }`
  )

  if (actualDate.isValid && userDate.isValid) {
    var diff = actualDate.diff(userDate, "years")
    return diff.years >= 18
  }

  return false
}

export const isLife = (day, month, year) => {
  var actualDate = DateTime.fromISO(DateTime.local().toISODate())
  var userDate = DateTime.fromISO(
    `${year}-${month.length === 1 ? "0" + month : month}-${
      day.length === 1 ? "0" + day : day
    }`
  )

  if (actualDate.isValid && userDate.isValid) {
    var diff = actualDate.diff(userDate, "years")
    return diff.years <= 100
  }

  return false
}

export const formatDateService = date => {
  var formatDate = DateTime.fromISO(date)
  return { day: formatDate.day, month: formatDate.month, year: formatDate.year }
}

export const getLuxonDate = ({ day, month, year }) => {
  var dt = DateTime.local().set({
    month: parseInt(month),
    year: parseInt(year === "" ? 2020 : year),
    day: day,
  })
  return dt
}

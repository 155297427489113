import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import ShopButton from '../../../assets/images/shop-button.svg'
import ModalRetailer from "../../modals/modal-retailer/modal-retailer"
import './shop-button.scss'

const ChatButton = props => {

  const RetailerQuery = useStaticQuery(
    graphql`
     query RetailerQuery {
         allContentfulPetysHome {
           nodes {
             carritosDeCompra {
               titulo
               nombreDelRetail
               redireccionDelRetail
               imagenDelRetail {
                 description
                 title
                 file {
                   url
                 }
               }
             }
           }
         }
       }
     `
  );

  const { setmodalActive } = props

  const retailerInfo = RetailerQuery.allContentfulPetysHome.nodes[0].carritosDeCompra

  const onClose = () => {
    setmodalActive(null)
  }

  const dataLayerPush = () => {
    const location = typeof window !== 'undefined' ? window?.location?.pathname.substring(1, window?.location?.pathname?.length - 1) : "";
  }

  const modalRetailer = <ModalRetailer onClose={onClose} retailerInfo={retailerInfo} />

  return (
    <figure className="f-button-shop" onClick={() => { dataLayerPush(); setmodalActive(modalRetailer) }}>
      <img src={ShopButton} alt="Boton de compra" />
    </figure>
  )
}

export default ChatButton

import React from 'react'
import './layout-modal.scss'

const GenericModal = props => {

  const { onClose } = props

  return (
    <div className={'f-curtain-modal'} onClick={onClose}>
      <div className="f-modal-container" onClick={(e) => e.stopPropagation()}>
        {props.children}
      </div>
    </div>
  )
}

export default GenericModal

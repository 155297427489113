import React, { useRef } from 'react'
import LayoutModal from '../layout-modal/layout-modal'
import useMobile from '../../../hooks/useMobile'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './modal-retailer.scss'

const GenericModal = props => {

  const { onClose, retailerInfo } = props
  const slider = useRef(null)
  const isMobile = useMobile();
  const cercaDeTi = retailerInfo.filter((retailer) => retailer.nombreDelRetail.toLowerCase().includes('cerca de ti') || retailer.titulo.toLowerCase().includes('cerca de ti'))

  const listItems = retailerInfo.map((retailer, key) => {
    if (!retailer.nombreDelRetail.toLowerCase().includes('cerca de ti') && !retailer.titulo.toLowerCase().includes('cerca de ti')) {
      return (
        <a href={retailer.redireccionDelRetail} target="_blank" className="f-reatailer-img" key={key}>
          <img src={retailer.imagenDelRetail.file.url} alt={retailer.imagenDelRetail.description} />
        </a>
      )
    }
  })

  /*Custom arrow*/
  const SampleNextArrow = () => {
    return (
      <div className={"f-slider-control-next"} onClick={() => slider.current.slickNext()}>
        <i className="fas fa-chevron-right"></i>
      </div>
    );
  }

  /*Custom arrow*/
  const PrevCustomArrow = () => {
    return (
      <div className={"f-slider-control-prev"} onClick={() => slider.current.slickPrev()}>
        <i className="fas fa-chevron-left"></i>
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: false,
    infinite: listItems.length >= 4,
    slidesToShow: isMobile ? 2 : 3,
    slidesToScroll: isMobile ? 2 : 3,
    pauseOnHover: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <PrevCustomArrow />,
  };

  return (
    <LayoutModal onClose={onClose}>
      <div className={'f-modal-retailer'}>
        <i className="fal fa-times f-close-button" onClick={onClose}></i>
        <h2 className="f-retailer-title">Compra nuestros productos en:</h2>
        <div className="f-slider-retailer-container">
          <Slider {...settings} ref={slider} className="f-slider-retailer">
            {listItems}
          </Slider>
          {cercaDeTi && cercaDeTi.length > 0 && <a href={cercaDeTi[0].redireccionDelRetail} target="_blank" className="f-reatailer-img-near-you">
            <img src={cercaDeTi[0].imagenDelRetail.file.url} alt={cercaDeTi[0].imagenDelRetail.description} />
          </a>}
        </div>
      </div>
    </LayoutModal>
  )
}

export default GenericModal

import React from 'react'
import homeLink from "../../../utils/siteUrl";
import './footer.scss'

const Footer = (props) => {

  const { footerInfo, showHeader} = props;
  const footerFilterInfo = footerInfo.allContentfulPetysHome.nodes[0];
  const logoInfo = footerFilterInfo.logo;
  const facebookInfo = {
    'redirection': footerFilterInfo.menu.redesSocialesFacebook,
    'imagen': footerFilterInfo.menu.redesSocialesFacebookImagen,
  }
  const instagramInfo = {
    'redirection': footerFilterInfo.menu.redesSocialesInstagram,
    'imagen': footerFilterInfo.menu.redesSocialesInstagramImagen,
  }
  const youtubeInfo = {
    'redirection': footerFilterInfo.menu.redesSocialesYoutube,
    'imagen': footerFilterInfo.menu.redesSocialesYoutubeImagen,
  }

  return (
    <footer className={"f-footer" + (!showHeader ? ' f-footer--nopadding' : '')}>
      <div className="f-footer-container">

        <div className="f-footer-row f-first-row">
          <div className="f-links-container">
            <figure className="f-footer-logo">
              <img
                src={logoInfo.file.url}
                alt={logoInfo.description}
              />
            </figure>
            <a
              href={homeLink + footerFilterInfo.footerRedireccionResponsabilidadSocial}
              className="f-link-item"
            >
              {footerFilterInfo.footerTituloResponsabilidadSocial}
            </a>
            <a
              href={homeLink + footerFilterInfo.footerRedireccionNosotros}
              className="f-link-item"
            >
              {footerFilterInfo.footerTituloNosotros}
            </a>
            <a
              href={homeLink + footerFilterInfo.footerRedireccionContactanos}
              className="f-link-item"
            >
              {footerFilterInfo.footerTituloContactanos}
            </a>
          </div>

          <div className="f-social-container">
            <div className="f-redes-container">
              <a href={instagramInfo.redirection} target="_blank" className="f-redes-item"><img src={instagramInfo.imagen.file.url} alt="Instagram" /></a>
              <a href={facebookInfo.redirection} target="_blank" className="f-redes-item"><img src={facebookInfo.imagen.file.url} alt="Facebook" /></a>
              <a href={youtubeInfo.redirection} target="_blank" className="f-redes-item"><img src={youtubeInfo.imagen.file.url} alt="Youtube" /></a>
            </div>
            <a href={homeLink + footerFilterInfo.footerRedireccionTerminosYPoliticas} className="f-link-item f-terms-conditions">
              {footerFilterInfo.footerTituloTerminosYPoliticas}
            </a>
            <a href={footerFilterInfo.footerRedireccionPoliticaDeDatos} target="_blank" className="f-link-item f-policy">
              {footerFilterInfo.footerTituloPolticaDeDatos}
            </a>
          </div>
        </div>

        <div className="f-footer-row f-second-row">
          <p className="f-copy-right">
            Todos los derechos reservados ©2020 Linea de atención y servicio al cliente 018000 52 48 48
                    </p>
        </div>

      </div>
    </footer>
  )
}

export default Footer

import axios from "axios";
import * as apiConstants from "../constants/apiConstants";
import qs from "qs";
import siteUrl from "../utils/siteUrl"

export const axiosInstance = axios.create();

function getIp() {
  return new Promise((resolve, reject) => {
    axios.get(apiConstants.GET_IP_ADDRESS).then((response) => {
      sessionStorage.setItem("ipAddress", response.data.ip)
      resolve(response.data.ip);
    }).catch((error) => {
      sessionStorage.setItem("ipAddress", "127.0.0.1");
      resolve("127.0.0.1");
    });
  });
}

const refreshAccessToken = (_originalRequest) => {
  _originalRequest._retry = true;

  const payload = qs.stringify({
    grant_type: "refresh_token",
    client_id: apiConstants.CLIENT_ID,
    refresh_token: JSON.parse(localStorage.getItem("TOKEN_INFO")).refreshToken
  });

  return axiosInstance.post(apiConstants.REFRESH_TOKEN, payload, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  }).then(response => {
    if (response.status === 200) {

      //Find and replace token data
      const _currentTokenData = JSON.parse(localStorage.getItem("TOKEN_INFO"));
      _currentTokenData["accessToken"] = response.data.access_token;
      _currentTokenData["idToken"] = response.data.id_token;

      //Set new token data
      localStorage.setItem("TOKEN_INFO", JSON.stringify(_currentTokenData));

      // Replace token in original request
      _originalRequest.headers.Authorization = response.data.id_token

      return axios(_originalRequest);
    }

  }).catch(err => {
    if (err.response.status === 400 && err.response.data.error === "invalid_grant") {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = siteUrl + "#sesion-expirada"
    } else {
      console.log(err)
    }
  });
};

axiosInstance.interceptors.request.use(async (config) => {
  const tokenResponse = await getIp();
  config.headers['Ip-Address'] = sessionStorage.getItem("ipAddress") ? sessionStorage.getItem("ipAddress") : tokenResponse;
  return config;
}, (error) => {
  return Promise.reject(error);
});

// handle response error general case
axiosInstance.interceptors.response.use(response => response,
  error => {
    const originalRequest = error.config;
    // Error
    const { response: { status } } = error;

    if (status === 401) {
      // Unauthorized request: maybe access token has expired!
      return refreshAccessToken(originalRequest);
    } else {
      return Promise.reject(error);
    }
  }
);
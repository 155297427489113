import React from 'react'
import homeLink from "../../../utils/siteUrl";
import './menu-mobile.scss'

const MenuMobile = (props) => {

  const {
    facebookInfo,
    instagramInfo,
    youtubeInfo,
    responsabilityOption,
    termsAndConditions,
    termsAndConditionsRedirec,
    policyPrivacyRedirec,
    policyPrivacy,
    onCloseAction,
    opened,
    loginOption,
    registerOption,
    userData,
    closeSession  } = props
  const nameResponsability = responsabilityOption.nombreDeLaOpcion.split(" ")[0].toLowerCase()

  return (
    <div className={"f-menu-mobile-mask " + (opened ? "opened" : 'closed')}>
      <div className="onClickClose" onClick={onCloseAction}></div>
      <div className={`f-menu-mobile-container ${userData ? "f-perfil-login" : ""}`}>
        {!userData && <i className="fal fa-times f-close-icon" onClick={onCloseAction}></i>}
        {userData && userData.name ?
          <div className="f-options-Perfil">
            <i className="fal fa-times f-close-icon" onClick={onCloseAction}></i>
            <p className="f-options-Perfil__user">¡Hola <b>{userData.name}</b>!</p>
            <div className="f-options-Perfil__options">
              <a href={homeLink + "mi-cuenta/perfil"}>Mi perfil</a>
              <p onClick={closeSession}>Cerrar sesión</p>
            </div>
          </div>
          :
          <div className="f-options-account">
            {loginOption &&
              <a
                href={homeLink + loginOption.redireccionDeLaOpcion}
                className="f-link-item"
              >
                {loginOption.nombreDeLaOpcion}
              </a>
            }
            {!userData &&
              <a
                href={registerOption.redireccionDeLaOpcion}
                className="f-link-item f-register-button"
              >
                <i className="icon-REGISTRO"></i>
                {registerOption.nombreDeLaOpcion}
              </a>
            }
          </div>
        }
        <div className="f-options-container">

          <a href={homeLink + responsabilityOption.redireccionDeLaOpcion}
            className={"f-nav-item " + "f-nav-item-" + nameResponsability}
          >
            <i className={"icon-" + nameResponsability.toUpperCase()}></i>{responsabilityOption.nombreDeLaOpcion}
          </a>

          <a href={homeLink + "nosotros/"} className={"f-nav-item "}>
            <i className="icon-PRODUCTOS"></i>
            Nosotros
          </a>

          <a href={homeLink + "contactanos/"} className={"f-nav-item "}>
            <i className="fal fa-envelope"></i>
            Contáctanos
          </a>

        </div>

        <span className="f-separator"></span>

        <div className="f-social-container">
          <p className="f-follow-text">Síguenos en</p>

          <div className="f-redes-container">
            <a href={instagramInfo.redirection} target="_blank" className="f-redes-item"><i className="icon-instagram"></i></a>
            <a href={facebookInfo.redirection} target="_blank" className="f-redes-item"><i className="icon-facebook"></i></a>
            <a href={youtubeInfo.redirection} target="_blank" className="f-redes-item"><i className="icon-youtube"></i></a>
          </div>
        </div>

        <div className="f-terms-container">
          <a href={homeLink + termsAndConditionsRedirec} className="f-terms-conditions">
            {termsAndConditions}
          </a>

          <a href={policyPrivacyRedirec} target="_blank" className="f-terms-conditions">
            {policyPrivacy}
          </a>
        </div>


        <p className="f-copy-right">
          Todos los derechos reservados ©2020 Linea de atención y servicio al cliente 018000 52 48 48
        </p>
      </div>
    </div>
  )
}

export default MenuMobile
